.App {
  text-align: center;
}

.App-header {
  background-color: #282c34;
  padding: 20px;
  color: white;
}

.orders-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin: 20px;
}

.order-card {
  background: #f9f9f9;
  border: 1px solid #ccc;
  border-radius: 8px;
  margin: 10px;
  padding: 20px;
  width: 300px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.order-card h2 {
  margin: 0 0 10px;
}

.order-card p {
  margin: 5px 0;
}

.order-card ul {
  list-style-type: none;
  padding: 0;
}

.order-card li {
  margin: 5px 0;
}

body {
  font-family: Arial, sans-serif;
  margin: 0;
  padding: 0;
  background-color: #f4f4f4;
}

nav {
  background-color: #333;
  color: white;
  padding: 1rem;
}

nav ul {
  list-style: none;
  display: flex;
  justify-content: space-around;
  padding: 0;
}

nav ul li a {
  color: white;
  text-decoration: none;
}

.menu, .orders {
  margin: 2rem;
  padding: 2rem;
  background: white;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

h1 {
  margin-bottom: 1rem;
}

ul {
  list-style: none;
  padding: 0;
}

li {
  margin-bottom: 1rem;
}

button {
  background-color: #4CAF50; /* Green background */
  border: none; /* Remove borders */
  color: white; /* White text */
  padding: 10px 20px; /* Some padding */
  text-align: center; /* Centered text */
  text-decoration: none; /* Remove underline */
  display: inline-block; /* Inline-block */
  font-size: 16px; /* Increase font size */
  margin: 4px 2px; /* Some margin */
  transition-duration: 0.4s; /* Make transition smooth */
  cursor: pointer; /* Add a pointer cursor on hover */
}

button:hover {
  background-color: white; /* White background on hover */
  color: black; /* Black text on hover */
  border: 2px solid #4CAF50; /* Add a green border on hover */
}

button:disabled {
  background-color: #cccccc; /* Grey background for disabled button */
  color: #666666; /* Dark grey text for disabled button */
  cursor: not-allowed; /* Not-allowed cursor for disabled button */
}