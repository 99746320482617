/* styles.css */

.menu {
    max-width: 800px;
    margin: auto;
    padding: 20px;
    background-color: #f9f9f9;
    border-radius: 8px;
  }
  
  .menu h1 {
    text-align: center;
  }
  
  .menu form {
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
  }
  
  .menu form input,
  .menu form textarea {
    margin-bottom: 10px;
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
  
  .menu form button {
    padding: 10px;
    background-color: #4CAF50;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
  
  .menu form button:hover {
    background-color: #45a049;
  }
  
  .menu ul {
    list-style-type: none;
    padding: 0;
  }
  
  .menu li {
    background-color: white;
    margin-bottom: 10px;
    padding: 10px;
    border-radius: 4px;
    border: 1px solid #ccc;
  }
  
  .menu li h2 {
    margin-top: 0;
  }
  
  .menu li button {
    padding: 5px 10px;
    background-color: #ff4d4d;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
  
  .menu li button:hover {
    background-color: #ff1a1a;
  }
  